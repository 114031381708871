import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import TextWithNote from '../components/text-with-note';
import Header from '../components/header';
import Container from '../components/container';
import Layout from '../components/layout';
import './book.css';

export default ({ location, data: { book, chapters } }) => {
  return (
    <Layout location={location}>
      <Container className="book--container">
        <article className="book">
          <Header>
            <h1>{book.name}</h1>
            <Helmet title={book.name} />
          </Header>
          {book.html && <TextWithNote {...book} />}
          <ul className="book--chapters-list">
            {!!chapters &&
              chapters.edges.map(({ node }) => {
                return (
                  <li key={node.id}>
                    <Link to={node.fields.slug}>{node.name}</Link>
                  </li>
                );
              })}
          </ul>
        </article>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $name: String!) {
    book(fields: { slug: { eq: $slug } }) {
      name
      html
      notes {
        id
        html
      }
    }
    chapters: allChapter(filter: { book: { eq: $name } }) {
      edges {
        node {
          name
          id
          fields {
            slug
          }
        }
      }
    }
  }
`;
